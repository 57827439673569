export const POLLING_TIME_IN_MINUTE = 60000 // 1 minute

export const FLAG = {
  USE_NEW_FILE_SERVICE: 'use_new_file_service',
  INTERNAL_QA_ACCESS_ENABLED: 'internal_qa_access_enabled',
  USE_IFRAME_MONOLITH: 'use_iframe_monolith',
  USE_DOWNLOAD_FILE_HACK: 'useDownloadFileHack',
  SHOW_TIME_RECORDER: 'show-timerecoder',
  SHOW_ONBOARDING_COLUMN_IN_ACCOUNT_OVERVIEW: 'show-onboarding-column-in-account-overview',
  SHOW_ONBOARDING_SECTION_IN_ACCOUNT_EDIT: 'show-onboarding-section-in-account-edit',
}
